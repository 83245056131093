import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { AuthService } from './services/auth-service';
import { Router } from '@angular/router';
import { Platform, Events, MenuController, NavController, IonRouterOutlet } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { ToastController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { RestApiService } from './rest-api.service';
import { Network } from '@ionic-native/network/ngx';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})

export class AppComponent {

	Name: string = "NA";
	Email: string = "NA";
	Username: string = "NA";

	Image: string;
	CompanyAddress: string = "NA";

	// set up hardware back button event.
	lastTimeBackPress = 0;
	timePeriodToExit = 2000;
	Token: any;
	toast : any;
	


	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		public authService: AuthService,
		private storage: Storage,
		private router: Router,
		public events: Events,
		public toastController: ToastController,
		private menu: MenuController,
		private authenticationService: AuthenticationService,
		public nav: NavController,
		private screenOrientation: ScreenOrientation,
		public api: RestApiService,
		private network: Network
	) {

		events.subscribe('UpdatdeMenu', () => {

			this.storage.get('UserDetails').then((val) => {
				
				if(val){
					
					this.Name = val.first_name + " " + val.last_name;
					this.Email = val.email;
					this.Username = val.username;
				}
				
	
				// this.CompanyAddress = val.address1 + " " + val.city + " " + val.country + ", " + val.postcode;
	
			});

		});


		this.storage.get('UserDetails').then((val) => {
				
			if(val){
				
				this.Name = val.first_name + " " + val.last_name;
				this.Email = val.email;
				this.Username = val.username;
			}
			

			// this.CompanyAddress = val.address1 + " " + val.city + " " + val.country + ", " + val.postcode;

		});


		this.storage.get('Token').then((val) => {

			if (val != null && val != '') {

				this.Token = val;

				

					this.initializeApp();

				

			} else {

				this.initializeApp();

			}

		});


	}


	


	initializeApp() {

		this.platform.ready().then(() => {

			if (this.platform.is('tablet') || this.platform.is('ipad') || this.platform.is('desktop')) {

				this.screenOrientation.unlock();

			} else {

				this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

			}

			this.platform.backButton.subscribe(() => {

				if (this.router.isActive('office', true)) {

					navigator['app'].exitApp(); // work in ionic 4

				}

			});


			this.statusBar.styleLightContent();

			this.splashScreen.hide();

			this.authenticationService.authenticationState.subscribe(state => {
				
				if (state) {

					this.storage.get('LoginUserName').then((val) => {

						let Username = val;
						this.storage.get('AllUserCredentails').then((val) => {
							
							let AllUserCredentails;

							if (val == null)
								AllUserCredentails = [];
							else
								AllUserCredentails = val;

							
								this.storage.get('UserDetails').then((val) => {
									
									let index = AllUserCredentails.findIndex(item => item.Username == Username);

									if (index >= 0){
										if(val.user_role=='local-playback'){
											this.router.navigate(['stream']);
										}else{
											this.router.navigate(['office']);

										}
									}else{
										this.router.navigate(['pin']);

									}
										
								});
							
						});

					});

				} else {

					this.router.navigate(['login']);

				}
			});


			this.checkNetwork();

		});
	}

	checkNetwork(){

		//Network Check start
		if(this.network.type =='none'){
			this.storage.set('IsNetworkEnabled', false);
		}else{
			this.storage.set('IsNetworkEnabled', true);
		}
		// watch network for a disconnection
		let disconnectSubscription = this.network.onDisconnect().subscribe(() => {			
			this.storage.set('IsNetworkEnabled', false);
			this.events.publish('UpdateNetworkInfo');
			this.presentToast('Your device is not connected to internet','danger');
		});
		
		
		// watch network for a connection
		let connectSubscription = this.network.onConnect().subscribe(() => {			
			this.storage.set('IsNetworkEnabled', true);
			this.events.publish('UpdateNetworkInfo');
			this.toast.dismiss();
			this.presentToastWithOptions('You are now connected to internet.','success');
			
		});
		
		
		//Network Check end
	}


	logout() {

		this.storage.get('IsSchedulerPlay').then((data) => {
			if (data == null)
				data = false;
			this.storage.get('AllUserCredentails').then((val) => {

				let temp;

				if (val == null)
					temp = [];
				else
					temp = val;

				this.storage.clear();

				this.menu.toggle();

				this.authService.logout();

				this.authenticationService.logout();

				this.storage.set('AllUserCredentails', temp);
				this.storage.set('IsSchedulerPlay', data);
				this.closemenu();
				this.router.navigate(['login']);

			});
		});

	}


	relogin(redirect) {

		this.storage.get('LoginUserName').then((val) => {

			let LoginUserName = val;
			this.storage.get('LoginPassword').then((val) => {

				let LoginPassword = val;

				this.api.postCall('token/generate-token', { 'username': LoginUserName, 'password': LoginPassword,  "domain": "falcon" })
					.subscribe(res => {
						console.log('generate-token :',res);
						
						if (res.status == 0) {

							this.authenticationService.relogin(res.data.token);

							setTimeout(() => {

								this.events.publish('UpdateToken');

								setTimeout(() => {

									if (redirect) {

										this.initializeApp();

									}

								}, 2000);

							}, 300);

						}

					}, err => {

						console.log('error :', err);

					});

			});

		});

	}

	async presentToastWithOptions(msg, type) {

		this.toast = await this.toastController.create({
			message: msg,
			showCloseButton: true,
			position: 'bottom',
			animated: true,
			closeButtonText: 'remove',
			color:type,
			duration: 2000
		});

		this.toast.present();

	}


	async presentToast(msg, type) {

		this.toast = await this.toastController.create({
			message: msg,
			showCloseButton: false,
			position: 'bottom',
			animated: true,
			color: type,
		});

		this.toast.present();

	}



	// validateToken(val) {

	// 	if (val != null && val != '') {

	// 		let Token = val;
	// 		let temp = Token.split('.');
	// 		let expiry = JSON.parse(atob(temp[1])).exp;
	// 		let milliseconds = Math.round(new Date().getTime() / 1000);

	// 		if (milliseconds >= expiry)
	// 			return false
	// 		else
	// 			return true;

	// 	} else {

	// 		return true;
	// 	}

	// }


	GoToPinPage() {

		this.menu.toggle();
		this.router.navigateByUrl('/changepin');

	}

	closemenu(){
		this.menu.enable(false);
	}

	// closemenu(){
	// 	this.menu.enable(false);
	// 	console.log("clikc");
  
	// }

	// togglemenu(){
	// 	this.menu.enable(true);

	// 	this.menu.toggle();
	// 	console.log("toggle");
  
	// }

	

}


