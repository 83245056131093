import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	authenticationState = new BehaviorSubject(false);

	constructor(
		private storage: Storage, 
		private plt: Platform,
		private router: Router,) {
		this.plt.ready().then(() => {
			this.checkToken();
		});
	}

	checkToken() {
		this.storage.get('Token').then(res => {
			
			if (res) {
				this.authenticationState.next(true);
			}
			
		})
	}

	login(token) {
		return this.storage.set('Token', token).then(() => {
			this.authenticationState.next(true);
		});
	}

	logout() {
		return this.storage.remove('Token').then(() => {
			this.authenticationState.next(false);
		});
	}

	isAuthenticated() {
		return this.authenticationState.value;
	}

	relogin(token) {
		return this.storage.set('Token', token).then(() => {});
	}

}