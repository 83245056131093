import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { GlobalService } from '../global/global.service';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { Events } from '@ionic/angular';

@Component({
	selector: 'app-popover',
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss']
})

export class PopoverComponent implements OnInit {

	volume: number = 0;
	wss_server: any;
	protocol: any;
	client_id: any;
	BoxDetails: any;
	StreamUrl: any;
	Token: any;
	socket: any;
	selectedDevice : any;
	BoxID : any;
	UserDetails : any;

	constructor(
		private storage: Storage, public globalService: GlobalService, public alertController: AlertController, 
		public toastController: ToastController, private media: Media,
		public events: Events
		) {

	}

	ngOnInit() {

		
		// this.storage.get('playOptions').then((val) => {
			
		// 	this.selectedDevice = val;

		// 	if(this.selectedDevice=='localplayback'){

		// 			this.storage.get('volume').then((val) => {
												
		// 				if (val != null) {

		// 					this.volume = val;

		// 				}else{
							
		// 					this.volume = 100;
		
		// 				}
	
		// 			});
				
		// 	}else{

		// 		this.storage.get('volume').then((val) => {

		// 			this.volume = val;

		// 		});
		// 	}
			
		// });

		this.storage.get('UserDetails').then((val) => {
			
			this.UserDetails = val;
			this.storage.get('BoxDetail').then((val) => {

				if(val){
					this.BoxID = val.PlayerID;
					
				}
				this.updateBoxInfo('Set', {});
				
			});

		});
		
	}


	ionViewWillLeave(): void {}

	updateBoxInfo(type, data){
		
		this.storage.get('boxesInfo').then((boxes) => {
			if(boxes!==null){

				// if(this.UserDetails['user_role'] != 'local-playback'){

					const boxExists = boxes.findIndex(box => box.BoxID == this.BoxID);
					if(boxExists>=0){

						if(type=='Box'){
							boxes[boxExists] = data;
							
						}else if(type=='BoxStreamInfo'){
							boxes[boxExists]['BoxStreamInfo'] = data;
			
						}else if(type=='BoxScheduleInfo'){
							boxes[boxExists]['BoxScheduleInfo'] = data;
			
						}else if(type=='LastPlayedStream'){
							boxes[boxExists]['LastPlayedStream'] = data;
			
						}else if(type=='PlayingDevice'){
							boxes[boxExists]['PlayingDevice'] = data;
			
						}else if(type=='Volume'){
							boxes[boxExists]['Volume'] = data;
			
						}
						this.BoxDetails = boxes[boxExists];
					}else{
						boxes = [{
							'BoxID':this.UserDetails['user_role'] != 'local-playback'?this.BoxID: 'local-playback',
							'BoxStreamInfo': data,
							'BoxScheduleInfo' : {
								'IsScheduleEnabled' : false,
								'scheduleList' :[],
								'ActiveSchedule' : {}
							},
							'LastPlayedStream' : {},
							'PlayingDevice' : 'box',
							'Volume' :100
						}];
						this.BoxDetails = boxes[0];
					}
					
				// }else{
				// 	// this.streamSession = boxes[0]['BoxStreamInfo'];
				// }
			}else{
				
				boxes = [{
					'BoxID':this.UserDetails['user_role'] != 'local-playback'?this.BoxID: 'local-playback',
					'BoxStreamInfo': data,
					'BoxScheduleInfo' : {
						'IsScheduleEnabled' : false,
						'scheduleList' :[],
						'ActiveSchedule' : {}
					},
					'LastPlayedStream' : {},
					'PlayingDevice' : 'box',
					'Volume' :100,

				}];
				this.BoxDetails = boxes[0];
			}
			
			this.storage.set('boxesInfo',boxes);
		});
	}

	async presentAlert(headertxt, messagetxt) {
		const alert = await this.alertController.create({
			header: headertxt,
			message: messagetxt,
			buttons: ['OK'],
			animated: true
		});

		await alert.present();
	}


	

	updateVolume(e) {

		// this.volume = e.detail.value;
		this.BoxDetails['Volume'] = e.detail.value;
		this.updateBoxInfo('Volume', this.BoxDetails['Volume']);

		this.events.publish('BoxDetailUpdated');

		// this.events.publish('changeInVolume');

	};

	sendEventToStreamPage(){
		

		setTimeout(() => {

			// this.storage.set('volume', this.volume);
			this.events.publish('changeInVolume');

		},500);

	}



	async presentToastWithOptions(message, color) {
		const toast = await this.toastController.create({
			message: message,
			showCloseButton: true,
			position: 'bottom',
			animated: true,
			closeButtonText: 'remove',
			color: color,
			duration: 5000
		});
		toast.present();
	}


}

