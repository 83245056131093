import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public Theme_Random = {
    'ThemeColor': '#00CD75', 'CardColors': {
      0: '#00BC9A',
      1: '#009F85',
      2: '#00CD75',
      3: '#00AD63',
      4: '#2A96D8',
      5: '#217EB5',
      6: '#AF78C0',
      7: '#9143A9',
      8: '#F4C339',
      9: '#F79B33',
      10: '#EB7D36',
      11: '#D65521',
      12: '#ED4D46',
      13: '#C23C35',
      14: '#00BC9A',
      15: '#009F85',
      16: '#00CD75',
      17: '#00AD63',
      18: '#2A96D8',
      19: '#217EB5',
    }
  };

  public Theme_Blue = {
    'ThemeColor': '#343F9B', 'CardColors': {
      0: '#5C69BB',
      1: '#4050B0',
      2: '#343F9B',
      3: '#2C368F',
      4: '#20267A',
      5: '#08479C',
      6: '#0B64BC',
      7: '#0074CE',
      8: '#0093F1',
      9: '#38A3F2',
      10: '#41C0F5',
      11: '#00A7F1',
      12: '#0097A3',
      13: '#0075B8',
      14: '#3CCEDF',
      15: '#006063',
      16: '#00828D',
      17: '#0097A3',
      18: '#00BAD2',
      19: '#3CCEDF',
    }
  };
  public Theme_Brown = {
    'ThemeColor': '#5F433A', 'CardColors': {
      0: '#8E6D65',
      1: '#7A564C',
      2: '#5F433A',
      3: '#4F3733',
      4: '#422B28',
      5: '#371C11',
      6: '#482415',
      7: '#6A301C',
      8: '#512210',
      9: '#B4502C',
      10: '#A73B1B',
      11: '#883216',
      12: '#672811',
      13: '#512210',
      14: '#3B1B0B',
      15: '#43240C',
      16: '#5D3010',
      17: '#773D16',
      18: '#9D4C1B',
      19: '#CE6324',
    }
  };
  public Theme_Green = {
    'ThemeColor': '#00786A', 'CardColors': {
      0: '#00A599',
      1: '#008779',
      2: '#00786A',
      3: '#00695B',
      4: '#004E41',
      5: '#146029',
      6: '#287D3A',
      7: '#2E8D44',
      8: '#399F4E',
      9: '#41AF57',
      10: '#639E43',
      11: '#518939',
      12: '#316929',
      13: '#24511F',
      14: '#1D3C18',
      15: '#003D21',
      16: '#00582D',
      17: '#00773A',
      18: '#00A14B',
      19: '#00C85C',
    }
  };
  public Theme_Grey = {
    'ThemeColor': '#526D78', 'CardColors': {
      0: '#8FA2AE',
      1: '#5E7C89',
      2: '#526D78',
      3: '#465A64',
      4: '#9D9D9D',
      5: '#262626',
      6: '#666666',
      7: '#616161',
      8: '#747474',
      9: '#9D9D9D',
      10: '#949D90',
      11: '#798177',
      12: '#5B625B',
      13: '#3E433D',
      14: '#333531',
      15: '#34302D',
      16: '#3F3D38',
      17: '#4E4A46',
      18: '#645F5C',
      19: '#807B78',
    }
  };

  public Theme_Orange = {
    'ThemeColor': '#EB4B2D', 'CardColors': {
      0: '#FF6F4E',
      1: '#FF5735',
      2: '#EB4B2D',
      3: '#DB4429',
      4: '#FDA43C',
      5: '#EB5224',
      6: '#F46C28',
      7: '#FF9F30',
      8: '#FF982F',
      9: '#FF6F2A',
      10: '#FFC037',
      11: '#FFB133',
      12: '#FA7F32',
      13: '#FF8E2E',
      14: '#FF6F2A',
      15: '#B8561E',
      16: '#E16825',
      17: '#FA7F32',
      18: '#FEA83F',
      19: '#FDBF46',
    }
  };
  public Theme_Pink = {
    'ThemeColor': '#F2407A', 'CardColors': {
      0: '#F88DAF',
      1: '#FB5F91',
      2: '#F2407A',
      3: '#791146',
      4: '#B01758',
      5: '#560B32',
      6: '#610B38',
      7: '#6F093F',
      8: '#7C1248',
      9: '#8C1250',
      10: '#AE47B9',
      11: '#9F27AC',
      12: '#7D1F9D',
      13: '#6D1B96',
      14: '#4E1588',
      15: '#371B8E',
      16: '#48299A',
      17: '#542DA2',
      18: '#693AB2',
      19: '#7F56BD',
    }
  };

  public Theme_Red = {
    'ThemeColor': '#F9443F', 'CardColors': {
      0: '#E97275',
      1: '#F6535E',
      2: '#F9443F',
      3: '#EB3B3F',
      4: '#FF2B37',
      5: '#971B22',
      6: '#C5001B',
      7: '#D81F2A',
      8: '#F02733',
      9: '#FF2B37',
      10: '#FF0024',
      11: '#C4001D',
      12: '#970016',
      13: '#D01F2D',
      14: '#55040B',
      15: '#6D1318',
      16: '#8B161D',
      17: '#A91922',
      18: '#D1212B',
      19: '#F32431',
    }
  };

  constructor() { }

  getTheme(name): any {
    if (name == 'Random')
      return this.Theme_Random;
    else if (name == 'Blue')
      return this.Theme_Blue;
    else if (name == 'Brown')
      return this.Theme_Brown;
    else if (name == 'Green')
      return this.Theme_Green;
    else if (name == 'Grey')
      return this.Theme_Grey;
    else if (name == 'Orange')
      return this.Theme_Orange;
    else if (name == 'Pink')
      return this.Theme_Pink;
    else if (name == 'Red')
      return this.Theme_Red;

  }


  // public StreamUrl = "http://stream.dimudi.com:8000/xmx_";

  // public ProximaServer = "wss://test-icecast.dimunet.com/stb";
  // public TestEnvApiUrl = "http://test-env.dimunet.com/api/";
  public AccmsNodeServer = "wss://accms.dimunet.com:8998";
  public TestAccmsNodeServer = "wss://accms.dimunet.com:8988";


}
