import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ToastController, MenuController } from '@ionic/angular';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { Events } from '@ionic/angular';
import { RestApiService } from '../rest-api.service';
import { AlertController } from '@ionic/angular';
import { AuthService } from '../services/auth-service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';


@Component({
	selector: 'app-setting',
	templateUrl: './setting.component.html',
	styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

	canPlayOnDevice = false;
	DeviceID : any;
	SchedulerList : any;
	SelectedSchedulerID : any;
	IsSchedulerVisible : boolean = true;
	UserDetails : any;
	BoxDetails: any;
	BoxID : any;
	
	constructor(
		private storage: Storage,
		public toastController: ToastController,
		private media: Media,
		public events: Events,
		public api: RestApiService,
		public alertController: AlertController,
		private menu: MenuController,
		private authenticationService: AuthenticationService,
		public authService: AuthService,
		private router: Router,
		public loadingController: LoadingController,

	) {}

	ionViewWillEnter() {
		
		
	}

	ngOnInit() {
		
		
		this.storage.get('UserDetails').then((val) => {
							
			this.UserDetails = val;
			this.getAllScheduler();
			
		});

		this.storage.get('BoxDetail').then((val) => {

			if(val){
				this.BoxID = this.UserDetails['user_role'] != 'local-playback'?val.PlayerID: 'local-playback';
				
			}else{
				this.BoxID = this.UserDetails['user_role'] != 'local-playback'?'': 'local-playback';
			}
			this.updateBoxInfo('Set', {});
		});

		// this.storage.get('IsBoxOnline').then((val) => {

		// 	this.canPlayOnDevice = val;			

		// });

		// this.storage.get('LoginUserName').then((val)=>{

		// 	let name = val.toLowerCase();
		// 	this.IsSchedulerVisible = true;
			
		// });
		
	}
	ionViewWillLeave() {}

	updateBoxInfo(type, data){

		this.storage.get('boxesInfo').then((boxes) => {
			if(boxes!==null){

				
					const boxExists = boxes.findIndex(box => box.BoxID == this.BoxID);
					if(boxExists>=0){

						if(type=='Box'){
							boxes[boxExists] = data;
							
						}else if(type=='BoxStreamInfo'){
							boxes[boxExists]['BoxStreamInfo'] = data;
			
						}else if(type=='BoxScheduleInfo'){
							boxes[boxExists]['BoxScheduleInfo'] = data;
			
						}else if(type=='LastPlayedStream'){
							boxes[boxExists]['LastPlayedStream'] = data;
			
						}else if(type=='PlayingDevice'){
							boxes[boxExists]['PlayingDevice'] = data;
			
						}else if(type=='Volume'){
							boxes[boxExists]['Volume'] = data;
			
						}
						this.BoxDetails = boxes[boxExists];
						
					}
			}
			this.storage.set('boxesInfo',boxes);
		});
	}

	changePlayOption(device, ev){

		this.updateBoxInfo('PlayingDevice', device);
		this.events.publish('BoxDetailUpdated');
		setTimeout(() => {
			this.events.publish('UpdateSchedulerSettings');
			
		}, 500);
		this.presentToastWithOptions('Play setting are updated', 'success');

	}

	
	


	toggleSchedulerSetting(val){

		setTimeout(() => {
			
			if(val.detail.checked){
				this.BoxDetails['BoxScheduleInfo']['IsScheduleEnabled'] = true;
				this.BoxDetails['LastPlayedStream']= this.BoxDetails['BoxStreamInfo'];
				
			}else{
				this.BoxDetails['BoxScheduleInfo']['IsScheduleEnabled'] = false;
				this.BoxDetails['BoxStreamInfo'] = this.BoxDetails['LastPlayedStream'];

			}
			
			this.updateBoxInfo('Box', this.BoxDetails)
			this.events.publish('BoxDetailUpdated');
			setTimeout(() => {
				this.events.publish('UpdateSchedulerSettings');
				
			}, 500);

		},300);
		
	}

	async getAllScheduler() {

		await this.api.getCallWithHeaders('getScheduler?UserID=' + this.UserDetails.id,'')
			.subscribe(res => {
				
				if (res.Status == 'SUCCESS') {

					this.SchedulerList = res.Data;
					this.BoxDetails['BoxScheduleInfo']['scheduleList'] = this.SchedulerList;
					let row = this.SchedulerList.filter(row => (row.is_active == 'true'));

					if(row[0])
						this.BoxDetails['BoxScheduleInfo']['ActiveSchedule'] = row[0];
						
					this.updateBoxInfo('BoxScheduleInfo', this.BoxDetails['BoxScheduleInfo'])
					this.events.publish('BoxDetailUpdated');
				} 

			}, err => {

				this.presentAlert('Oops!!', err);

			});

	}


	async changeActiveScheduler(data){
		
		this.presentLoading();
		let param = {
			'SchedulerID' : data.id,
			'UserID' : this.UserDetails.id
		};
		
		await this.api.postCallWithHeaders('activateStream', param,'')
			.subscribe(res => {
										
				if (res.Status == 'SUCCESS') {
					
					this.BoxDetails['BoxScheduleInfo']['ActiveSchedule'] = data;
					this.updateBoxInfo('BoxScheduleInfo', this.BoxDetails['BoxScheduleInfo']);
					console.log('setting page',this.BoxDetails);
					
					setTimeout(() => {
						this.events.publish('BoxDetailUpdated');

						setTimeout(() => {
							this.events.publish('PlayScheduler');

						}, 500);
					}, 500);
					
					this.presentToastWithOptions('Scheduler successfully activated. Now Loading stream.','success');
					this.loadingController.dismiss();

				} else{

					this.presentAlert('Oops!!', 'There is an error in activating schedule profile.');
					this.loadingController.dismiss();

				}

			}, err => {
				
				this.loadingController.dismiss();
				console.log('get all profile error :', err);
				this.presentAlert('Oops!!', err);

			});

	}


	logout() {
		
		this.storage.get('AllUserCredentails').then((val) => {

			let temp;

			if (val == null)
				temp = [];
			else
				temp = val;

			this.storage.clear();

			this.menu.toggle();

			this.authService.logout();

			this.authenticationService.logout();

			this.storage.set('AllUserCredentails', temp);
			this.router.navigate(['login']);

		});
		
	}


	async presentToastWithOptions(message, color) {

		const toast = await this.toastController.create({
			message: message,
			showCloseButton: true,
			position: 'bottom',
			animated: true,
			closeButtonText: 'remove',
			color: color,
			duration: 5000
		});

		toast.present();
		
	}

	
	async presentAlert(headertxt, messagetxt) {

		const alert = await this.alertController.create({
			header: headertxt,
			message: messagetxt,
			buttons: ['OK'],
			animated: true
		});

		await alert.present();
	}

	async presentLoading() {

		const loading = await this.loadingController.create({
			message: 'Loading',
		});

		return await loading.present();

	}

}
