import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
  { path: 'login', loadChildren: './login/login.module#LoginPageModule'},
  { path: 'stream', loadChildren: './stream/stream.module#StreamPageModule', canActivate: [AuthGuard] },
  { path: 'office', loadChildren: './office/office.module#OfficePageModule', canActivate: [AuthGuard] },
  { path: 'schedule', loadChildren: './schedule/schedule.module#SchedulePageModule' },
  { path: 'pin', loadChildren: './pin/pin.module#PinPageModule' },
  { path: 'changepin', loadChildren: './changepin/changepin.module#ChangepinPageModule' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
