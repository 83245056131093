import { Component, OnInit, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { RestApiService } from '../rest-api.service';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { KeyValue } from '@angular/common';


@Component({
	selector: 'app-addprofile',
	templateUrl: './addprofile.component.html',
	styleUrls: ['./addprofile.component.scss']
})
export class AddprofileComponent implements OnInit {

	@Input() StreamIndex: number;
	
	StreamInfo = [];
	theme: any;
	SelectedScheduler : any;
	SelectedStream : any;
	AllStreams =[];

	form = {
		name : '',
		day: '',
		start: 0,
		end: 0,
		stream: ''
	};

	IsEdit : boolean =false;
	isStreamLoaded : boolean =false;
	volume : any;
	UserDetails : any;
	BoxInfo : any;

	constructor(navParams: NavParams, public api: RestApiService, public alertController: AlertController, public loadingController: LoadingController, private storage: Storage,
		public toastController: ToastController, public modalController: ModalController ) {
			
			setTimeout(() => {	
				
				if(this.StreamIndex >= 0){
					this.IsEdit = true;
				}

	
				this.storage.get('SelectedScheduler').then((val) => {
					
					this.SelectedScheduler = val;
					this.SelectedStream = this.SelectedScheduler.schedulerData[this.StreamIndex];
						
					if(this.IsEdit){
						
						this.form = {

							name :  this.SelectedScheduler.title,
							day: this.SelectedStream.day,
							start: this.SelectedStream.start/60,
							end: (this.SelectedStream.end/60),
							stream: this.SelectedStream.streamID

						};
						
					}

				});
			
				
			}, 300);


			this.storage.get('volume').then((val) => {

				this.volume = val;
				
			});


	}

	ngOnInit() {

		this.storage.get('UserDetails').then((val) => {
			this.UserDetails = val;	
		});

		this.storage.get('BoxDetail').then((val) => {

			if(val){
				this.BoxInfo = val
				// this.deviceName = val.Description;
				// this.BoxID = val.PlayerID;
				
			}

			setTimeout(() => {
				this.getConsoleData();
			}, 300);

		});

	}

	validateProfile() {
		
		if(this.form.day == '' || this.form.stream == '' )
			return false
		else
			return true;
		
	}



	async saveProfile() {

		if (this.validateProfile() == false) {

			this.presentToastWithOptions('Please fill out all form.', 'danger', 5000);
			return;

		}
		
		
		if(this.IsEdit)
			this.SelectedStream = this.SelectedScheduler.schedulerData[this.StreamIndex];
				
			
		let tempIndex = this.StreamInfo.findIndex(ob => (ob['stream_id'] == this.form.stream ) );
		let colorIndex = tempIndex + parseInt('1');
		
		this.SelectedScheduler.schedulerData ={

			day: this.form.day,
			start: this.form.start * 60,
			end: (Math.round(this.form.end) * 60) -1,
			streamId: this.StreamInfo[ tempIndex ]['stream_id'],
			streamShortName: this.StreamInfo[tempIndex]['stream_shortcut'],
			streamName: this.StreamInfo[tempIndex]['stream_name'],
			streamColor: this.theme['color'+ colorIndex],
			streamUrl: this.StreamInfo[tempIndex]['stream_url'],
			mountPoint: null,
			volume: this.volume,
			mediaSourceUri: null,

		};
		
		let param = {

			SchedulerID : this.SelectedScheduler.id,
			name: this.SelectedScheduler.name,
			active: true,
			SchedulerStreamID:this.IsEdit?this.SelectedStream.schedulerStreamID:'',
			schedulerData: this.SelectedScheduler.schedulerData
			
		};
		
		await this.api.postCallWithHeaders('saveStream', param, '')
			.subscribe(res => {
				
				this.presentToastWithOptions('New stream successfully added.', 'success', 5000);

				this. form = {
					name : '',
					day: '',
					start: 0,
					end: 0,
					stream: ''
				};

				this.modalController.dismiss({
					'result': 'val'
				});

				this.storage.set('SelectedScheduler', param);
				
			}, err => {

				console.log('getTheme error :', err);
				this.presentAlert('Oops!!', err);

			});
	}

	close(){

		this.modalController.dismiss({'result': 'closed'});

	}


	async presentToastWithOptions(message, color, duration) {

		const toast = await this.toastController.create({
			message: message,
			showCloseButton: true,
			position: 'bottom',
			animated: true,
			closeButtonText: 'remove',
			color: color,
			duration: duration
		});

		toast.present();

	}

	async presentAlert(headertxt, messagetxt) {

		const alert = await this.alertController.create({
			header: headertxt,
			message: messagetxt,
			buttons: ['OK'],
			animated: true
		});

		await alert.present();
	}

	async presentLoading() {

		const loading = await this.loadingController.create({
			message: 'Loading',
			duration: 2000
		});

		return await loading.present();

	}

	valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
	
	
		return a['value']['name']['en'].localeCompare(b['value']['name']['en']);
	}


	async getConsoleData() {

		let StreamGroupID = (this.UserDetails['user_role']=='local-playback'? this.UserDetails['stream_group_id']:this.BoxInfo.stream_group_id);
		if(StreamGroupID){

			await this.api.getCall('getConsolerAppData?StreamGroupID=' +StreamGroupID)
			.subscribe(res => {

				if (res.Status == 'SUCCESS') {
					if(res.Data.theme.length!=0){
						this.theme = res.Data.theme[0];
						this.theme.image = 'http://test-env.dimunet.com/test-cms/public/images/logos/'+this.theme.image;
					
					}else{
						this.presentAlert('Oops!!', 'Theme details not available please try again.');

					}
					if(res.Data.streamList.length!=0){

						for (let item of res.Data.streamList) {

							if (item.stream_name) 
								this.StreamInfo.push(item);
	
						}
						this.StreamInfo.sort((a, b) => (a.position > b.position) ? 1 : -1);
						this.isStreamLoaded = true;	
						
						
					}else{
						this.presentAlert('Oops!!', 'Stream details not available please try again.');

					}

				} else {

					this.presentAlert('Oops!!', 'Something is wrong. Please try again.');
					
				}

				
			}, err => {

				console.log('error :', err);
				this.presentAlert('Oops!!', err);
				

			});

		}else{
			this.presentAlert('Oops!!', 'No Group is assigned to this user.');

		}
		

	}
}
