import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { AuthService } from './services/auth-service';
import { SocketService } from './services/socket-service';
import { PopoverComponent } from './popover/popover.component';
import { Device } from '@ionic-native/device/ngx';
import { SettingComponent } from './setting/setting.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { AddprofileComponent } from './addprofile/addprofile.component';

import {FormsModule} from '@angular/forms';
import { AddprofilenameComponent } from './addprofilename/addprofilename.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { Network } from '@ionic-native/network/ngx';

@NgModule({
  declarations: [AppComponent, PopoverComponent, SettingComponent, AddprofileComponent, AddprofilenameComponent],
  entryComponents: [PopoverComponent, SettingComponent, AddprofileComponent, AddprofilenameComponent],
  imports: [
    
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    BrowserModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar, 
    SplashScreen,
    AuthService,
    SocketService,
    Device,
    ScreenOrientation,
    Media,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
