import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-addprofilename',
  templateUrl: './addprofilename.component.html',
  styleUrls: ['./addprofilename.component.scss']
})

export class AddprofilenameComponent implements OnInit {

  // @Input() prop1: number;
	// @Input() prop2: number;
	// StreamInfo: any = [];
	Token: string;
	UserData : any;
	// theme: any;

	form = {
		name : '',
		
	};

  constructor(navParams: NavParams, public api: RestApiService, public alertController: AlertController, public loadingController: LoadingController, private storage: Storage,
		public toastController: ToastController, public modalController: ModalController ) {}

  ngOnInit() {
	this.storage.get('UserDetails').then((val) => {
		this.UserData = val;				
		console.log(val);
	});

	
  }


  validateProfile() {
		
		if(this.form.name == '' )
			return false
		else
			return true;
		
	}

	async saveName() {

		if (this.validateProfile() == false) 
			return;

		let param = {

			Title: this.form.name,//"" to make number a integer value
			UserID: this.UserData.id

		};

		// console.log('param : ',param);
		await this.api.postCallWithHeaders('addScheduler', param, this.Token)
			.subscribe(res => {
				console.log('save profile : ', res);


				if (res.Status=='SUCCESS'){
					this.presentToastWithOptions('New schedular successfully added.', 'success', 5000);

					this. form = {
						name : '',
						
					};

					this.modalController.dismiss({
						'result': 'val'
					})
				}
					
				else
					this.presentAlert('Oops!!', 'Data not saved.');

			}, err => {

				console.log('getTheme error :', err);
				this.presentAlert('Oops!!', err);

			});
	}

	close(){

		this.modalController.dismiss({});

	}


	async presentToastWithOptions(message, color, duration) {

		const toast = await this.toastController.create({
			message: message,
			showCloseButton: true,
			position: 'bottom',
			animated: true,
			closeButtonText: 'remove',
			color: color,
			duration: duration
		});

		toast.present();

	}

	async presentAlert(headertxt, messagetxt) {

		const alert = await this.alertController.create({
			header: headertxt,
			message: messagetxt,
			buttons: ['OK'],
			animated: true
		});

		await alert.present();
	}

	async presentLoading() {

		const loading = await this.loadingController.create({
			message: 'Loading',
			duration: 2000
		});

		return await loading.present();

	}

}
