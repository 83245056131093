import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
const apiUrl = "https://accms.dimunet.com/api/";//"https://api-gtw-test.dimunet.com/";
const cmsapiUrl = "http://test-env.dimunet.com/webplayer-api/";
const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
	providedIn: 'root'
})
export class RestApiService {
	constructor(private http: HttpClient, private storage: Storage, public alertController: AlertController,
		private router: Router, ) { }

	

	public handleError(error: HttpErrorResponse) {
		console.log('handleError : ', error);
		var errorMessage ='';
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {

			if (error.status == 401) {
				var errorMessage = "Your token has been expired you need to login first.";
			
			}
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		if(error.statusText){
			errorMessage = error.statusText;
		}else if(errorMessage==''){
			errorMessage = 'Something went wrong, please try again later.';
		}
		// return an observable with a user-facing error message
		return throwError(errorMessage);
	}

	async presentAlert(headertxt, messagetxt) {
		const alert = await this.alertController.create({
			header: headertxt,
			message: messagetxt,
			buttons: ['OK'],
			animated: true
		});

		await alert.present();
	}

	private extractData(res: Response) {
		// console.log('Api Response : ',res);
		
		let body = res;
		return body || {};
	}


	getCall(url): Observable<any> {

		return this.http.get(`${apiUrl}` +url, httpOptions)
		.pipe(
			map(this.extractData),
			catchError(this.handleError)
		);
	}
 

	postCall(callName, data): Observable<any> {
		
		const url = `${apiUrl}` + callName;
		return this.http.post(url, data, httpOptions)
			.pipe(
				map(this.extractData),
				catchError(this.handleError)
			);
	}


	postCallWithHeaders(callName, data, token): Observable<any> {

		const url = `${apiUrl}` + callName;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token, 'Access-Control-Allow-Origin':'*' })
		};
		return this.http.post(url, data, httpOptions)
			.pipe(
				map(this.extractData),
				catchError(this.handleError)
			);
	}

	getCallWithHeaders(callName, token): Observable<any> {

		const url = `${apiUrl}` + callName;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
		};
		return this.http.get(url, httpOptions)
			.pipe(
				map(this.extractData),
				catchError(this.handleError)
			);
	}

	getCallWithCustomHeaders(callName, username, password): Observable<any> {

		const url = `${apiUrl}` + callName;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'username': username, 'password' : password,  })
		};
		return this.http.get(url, httpOptions)
			.pipe(
				map(this.extractData),
				catchError(this.handleError)
			);
	}


	postCallToCms(callName, data): Observable<any> {
		
		const url = `${cmsapiUrl}` + callName;
		return this.http.post(url, data, httpOptions)
			.pipe(
				map(this.extractData),
				catchError(this.handleError)
			);
	}

	getCallToCms(callName): Observable<any> {

		return this.http.get(`${cmsapiUrl}` +callName, httpOptions)
		.pipe(
			map(this.extractData),
			catchError(this.handleError)
		);
	}

}
