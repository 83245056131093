import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { GlobalService } from '../global/global.service';

@Injectable({
	providedIn: 'root'
})

export class SocketService {

	socket: any;
	wss_server: any;
	Token: string;

	constructor(private storage: Storage, public globalService: GlobalService, ) {



	}

	connect(): void {

		setTimeout(() => {

			this.storage.get('Token').then((val) => {
				
				this.Token = val;

				this.storage.get('ConnectedSocketEvent').then((val) => {
					
					if(val== null || val==''){

						console.log('newly connected');
						
						// this.wss_server = this.globalService.ProximaServer;

						this.socket = new WebSocket(this.wss_server, this.Token);

						this.storage.set('ConnectedSocketEvent',this.socket);

						this.socketEvents();

					}else{
						console.log('already connected');
						
						this.socket = val;
						this.socketEvents();
					}

					
					
				});



			});

		}, 300);

	}

	socketEvents():void{

		this.socket.onopen = (event: Event) => {

			console.log("Socket has been opeened !");

		};


		this.socket.onmessage = (event) => {
			let response = JSON.parse(event.data);
			console.log("Socket has been responsed !", response);


		};

		this.socket.onerror = (event: Event) => {


			console.log("Socket has a error!", event);

		};

		this.socket.onclose = (event: Event) => {

			console.log("Socket has close on stream page!", event);

		};

	}


	send(msg):any{
		console.log('sending from here');
		
		this.socket.send(msg);
	}


	close(): void {

		this.socket.close();
	
	}

}
