import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	// canActivate(
	//   next: ActivatedRouteSnapshot,
	//   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
	//   return true;
	// }

	constructor(public auth: AuthenticationService) { }

	canActivate(route: ActivatedRouteSnapshot): any {

		return this.auth.isAuthenticated();
	}


}
